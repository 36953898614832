import Modal from 'bootstrap/js/dist/modal';

console.log('>Modal', Modal);
$(function () {

  var svg = document.querySelector("svg"),
    svgMap = document.getElementById('world-map');

  if (svgMap) {

    console.log('process svgMap...');

    // Highlight active countries
    $.each(window.countryTitles, function (iso, countryTitle) {
      if (!iso) {
        return;
      }

      console.log(iso, countryTitle);
      $('[data-iso="' + iso + '"]').addClass('active');
    });

    function positionPins () {
      $.each(window.countryProjects, function (iso, countryProject) {
        if (!iso) {
          return;
        }
        var element = $('[data-iso="' + iso + '"]');

        var $pin = $('[data-pin="' + iso + '"]');
        var $tooltip = $('[data-tooltip="' + iso + '"]');

        var offsetTop = window.pageYOffset + element[0].getBoundingClientRect().top
        var offsetLeft = window.pageXOffset + element[0].getBoundingClientRect().left
        // var offsetTop = element.offset().top;
        // var offsetLeft = element.offset().left;
        var width = element[0].getBoundingClientRect().width;
        var height = element[0].getBoundingClientRect().height;

        $pin.css({position: 'absolute'});

        var toolW = $pin.width();
        var toolH = $pin.height();
        var pinTop = (offsetTop + height / 2 - 30);
        var pinLeft = (offsetLeft + width / 2 - 12);

        $pin.css({
          top: pinTop + "px",
          left: pinLeft + "px",
        });

        // Position tooltip

        var offsetTop = element.offset().top;
        var offsetLeft = element.offset().left;
        var width = element[0].getBoundingClientRect().width;
        var height = element[0].getBoundingClientRect().height;

        var toolW = $tooltip.width();
        var toolH = $tooltip.height();

        $tooltip.css({position: 'absolute'});
        $tooltip.css({
          top: (pinTop - 30) + "px",
          left: (pinLeft + (width - toolW) / 2) + "px",
        });

      });
    }


    // Add pins
    $.each(window.countryProjects, function (iso, countryProject) {
      if (!iso) {
        return;
      }

      var countryTitle = window.countryTitles[iso];
      console.log(iso, countryProject);

      var element = $('[data-iso="' + iso + '"]');

      element.addClass('active-with-pin');

      var $pin = $("<img class='map-pin' data-pin='" + iso + "' src='/img-not-in-build/pin.svg' style='width: 25px'/>");

      $pin.hover(function () {
        element.addClass('hover');
      }, function () {
        element.removeClass('hover');
      })
      $('body').append($pin);



      // Add tooltips
      var $tooltip;

      $tooltip = $("<div data-tooltip='" + iso + "' class='tooltip top'><div class='tooltip-inner'>" + window.countryTitles[iso] + "</div><div class='tooltip-arrow'></div></div>");

      $('body').append($tooltip);

      $pin.hover(function () {
        $tooltip.addClass("show");
      }, function () {
        $tooltip.removeClass("show");
      });

      element.hover(function () {
        $tooltip.addClass("show");
      }, function () {
        $tooltip.removeClass("show");
      });

      function openProject() {
        var uri = '/entry/projects-modal/' + countryProject;

        $('#project-loading').show();
        $('#project-content').html('');

        $('#project-content').load(uri, {}, function () {
          $('#project-loading').hide();
        })

        var modal = new Modal(document.getElementById('projectModal'), {});
        modal.show();

      }

      element.click(openProject);
      $pin.click(openProject);

    });


    positionPins();

    $(window).resize(function () {
      positionPins();
    })


    // Add navigation to reports


  }

})